




















import { Getter, Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";

import DotCard from "@/components/dots/card.vue";
import FilterSelect from "@/components/general/filter.vue";
import DotPlaceholder from "@/components/dots/placeholder.vue";

@Component({
  components: {
    DotCard,
    FilterSelect,
    DotPlaceholder,
  },
})
export default class PageDots extends Vue {
  @Getter("workspace/viewing") workspace!: Workspace;

  @Getter("dot/all") dots!: Dot[];

  @Action("dot/index") indexDots!: (payload: SearchIndexPayload) => Promise<Dot[]>;

  loading = false;

  arena: number[] = [];

  labels: number[] = [];

  sort: number[] = [];

  sortOptions: SelectOption[] = [
    { id: 1, label: "Nieuwste eerst", value: { created_at: "desc", id: "desc" } },
    { id: 2, label: "Oudste eerst", value: { created_at: "asc", id: "desc" } },
  ];

  @Debounce(500)
  private searchDots() {
    const payload: SearchIndexPayload = {
      per_page: 9,
      s: { created_at: "desc", id: "desc" },
      q: { and: { workspace_id: `${this.workspace.id}` } },
    };

    if (this.sort) {
      const foundSort: SelectOption | undefined = this.sortOptions.find((option: SelectOption) => this.sort.includes(option.id));
      if (foundSort) {
        payload.s = foundSort.value;
      }
    }

    if (this.arena) {
      const foundArena: SelectOption[] | undefined = this.arenaOptions.filter((option: SelectOption) => this.arena.includes(option.id));
      if (foundArena) {
        payload.q = {
          ...payload.q,
          and: {
            ...(payload?.q?.and || {}),
            or: { arena_level: foundArena.map((option: SelectOption) => option.id) },
          },
        };
      }
    }

    this.loading = true;
    this.indexDots(payload).finally(() => (this.loading = false));
  }

  get arenaOptions(): SelectOption[] {
    return [
      { id: 1, label: this.workspace.arena_level_1_title },
      { id: 2, label: this.workspace.arena_level_2_title },
      { id: 3, label: this.workspace.arena_level_3_title },
    ];
  }

  get labelsOptions(): SelectOption[] {
    return [];
  }
}
